import classNames from 'classnames/bind';
import { trackCustomEvent } from 'gatsby-plugin-google-analytics';
import addToMailchimp from 'gatsby-plugin-mailchimp';
import React, { useState } from 'react';
import { Layout } from '../components/Layout';
import { SEO } from '../components/SEO';
import ScreenshotMobileImg from '../images/sendeo-campaign-summary-screenshot-mobile.png';
import ScreenshotImg from '../images/sendeo-campaign-summary-screenshot.png';
import * as styles from './index.module.scss';
import { NewsletterForm } from '../components/NewsletterForm';

const cx = classNames.bind(styles);

const IndexPage = () => {
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);
  const [pending, setPending] = useState(false);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setError('');
    setSuccess(false);
    setPending(true);
    const { result, msg } = await addToMailchimp(email);
    setPending(false);

    if (result === 'error') {
      const parts = msg.split('-');

      setError(parts.length > 1 ? parts[1] : parts[0]);
    } else {
      trackCustomEvent({
        category: 'Private Beta',
        action: 'Email List Sign Up',
        label: 'Sign Up',
      });
      setSuccess(true);
    }
  };

  return (
    <Layout>
      <SEO />

      <div className={cx('hero')}>
        <div className="container">
          <h2>Email marketing platform. Simple, powerful, made for you.</h2>
          <p>
            Beautiful dashboards, campaign management, subscription forms, audience segmentation, email automation,
            analytics and more. Sendeo is a simpler and better alternative to expensive email marketing solutions.
          </p>

          <NewsletterForm narrow />
        </div>
      </div>

      <img
        className={cx('screenshot', 'desktop')}
        src={ScreenshotImg}
        alt="Sendeo Campaign Summary MacBook Screenshot"
      />
      <img
        className={cx('screenshot', 'mobile')}
        src={ScreenshotMobileImg}
        alt="Sendeo Campaign Summary Mobile Screenshot"
      />
    </Layout>
  );
};

export default IndexPage;
